
          @import "@/assets/scss/_variables.scss";
        







































































.v-dialog.common-dialog {
  .v-card {
    .v-card__text {
      padding: 1.2rem 1rem 1rem;
      img {
        width: 100%;
      }
    }
    .v-card__actions {
      position: relative;
      padding: 1rem 1rem 1.5rem;
      justify-content: flex-end;
      .v-input--checkbox {
        position: absolute;
        left: 20px;
      }
    }
  }
}
