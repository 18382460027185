
          @import "@/assets/scss/_variables.scss";
        




























































































































.hGap {
  height: 10px;
}
.agree {
  padding: 8rem 0 12rem;
  .section-inner {
    max-width: 800px;
  }
  .signup-header {
    padding: 0 1rem 2.5rem;
    text-align: center;
    img {
      width: 170px;
    }
    .input-guide {
      margin-top: 1.5rem;
      color: $primary-color;
    }
  }
  .check-group {
    padding: 2rem 0;
    h4 {
      border-bottom: 1px solid #ddd;
    }
  }
  .checkbox-row {
    position: relative;
    .v-btn {
      position: absolute;
      right: 0;
      top: -3px;
      .v-btn__content {
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 768px) {
  div.agree {
    padding: 4rem 0 8rem;
  }
}
